<template>
  <div>
    <cs-table
      :filterable="false"
      :loading="loading"
      :headers="headers"
      :items="items"
      :pagination="pagination"
      base-classes="card card-custom card-stretch gutter-b rounded-top-0"
      @filtered="handelFiltering"
    >
      <template #after-header-filterable>
        <b-col class="my-2 my-md-0 ml-auto text-right">
          <b-btn variant="light-primary" class="font-weight-bolder" @click="openModal()">
            <i class="fas fa-user"></i>
            Add Board Member
          </b-btn>
        </b-col>
      </template>
      <template #[`item.first_name`]="{ item }">
        {{ get(item, 'attributes.first_name') + ' ' + get(item, 'attributes.last_name') }}
      </template>
      <template #[`item.type`]="{ item }">
        <span
          class="label font-weight-bold label-lg label-inline"
          :class="[Boolean(get(item, 'attributes.board_president')) ? 'label-light-success' : 'label-light-info']"
        >
          {{ Boolean(get(item, 'attributes.board_president')) ? 'President' : 'Board Member' }}
        </span>
      </template>
      <template #[`item.status`]="{ item }">
        <span class="switch d-inline-flex">
          <label>
            <input v-model="item.attributes.board_member_active" type="checkbox" @input="updateStatus(item)" />
            <span></span>
          </label>
        </span>
      </template>
      <template #[`item.actions`]="{ item }">
        <b-dropdown class="btn-icon" variant="clean" menu-class="w-max-content" right no-caret>
          <template v-slot:button-content>
            <i class="ki ki-bold-more-hor font-size-md pr-0"></i>
          </template>
          <ul class="navi navi-hover py-5">
            <li class="navi-item">
              <a class="navi-link" href="javascript:;" @click="openModal(item, 'confirmationModal')">
                <span class="navi-icon">
                  <i class="la la-trash font-size-h1 mr-2"></i>
                </span>
                <span class="navi-text">Delete</span>
              </a>
            </li>
            <li class="navi-item">
              <a class="navi-link" href="javascript:;" @click="openModal(item)">
                <span class="navi-icon">
                  <i class="la la-edit font-size-h1 mr-2"></i>
                </span>
                <span class="navi-text">Edit</span>
              </a>
            </li>
            <li class="navi-item">
              <a class="navi-link" href="javascript:;" @click="openModal(item, 'mailModal')">
                <span class="navi-icon">
                  <i class="la la-refresh font-size-h1 mr-2"></i>
                </span>
                <span class="navi-text">Update and Resend Email</span>
              </a>
            </li>
          </ul>
        </b-dropdown>
      </template>
    </cs-table>
    <b-modal v-model="modal" :title="`${isEdit ? 'Edit' : 'Add'} Board Member`" centered footer-class="text-right">
      <b-form-group>
        <template v-slot:label>
          Board member's First name
          <span class="text-danger">*</span>
        </template>
        <b-form-input v-model="form.first_name" :state="validateState($v.form.first_name)" />
      </b-form-group>
      <b-form-group>
        <template v-slot:label>
          Board member's Last name
          <span class="text-danger">*</span>
        </template>
        <b-form-input v-model="form.last_name" :state="validateState($v.form.last_name)" />
      </b-form-group>
      <b-form-group>
        <template v-slot:label>
          Email
          <span class="text-danger">*</span>
        </template>
        <b-form-input v-model="form.email" :state="validateState($v.form.email)" />
      </b-form-group>
      <b-form-group>
        <template v-slot:label>
          Phone number
          <span class="text-danger">*</span>
        </template>
        <b-form-input
          type="text"
          v-model="form.phone"
          :state="validateState($v.form.phone)"
          maxlength="13"
          v-mask="'(###)###-####'"
        />
      </b-form-group>
      <b-form-group>
        <b-row>
          <b-col class="col-form-label">Is this member a Board President?</b-col>
          <b-col cols="auto">
            <span class="switch">
              <label>
                <input type="checkbox" v-model="form.board_president" />
                <span></span>
              </label>
            </span>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group class="mb-0">
        <b-row>
          <b-col class="col-form-label">Is this member active?</b-col>
          <b-col cols="auto">
            <span class="switch">
              <label>
                <input type="checkbox" v-model="form.board_member_active" />
                <span></span>
              </label>
            </span>
          </b-col>
        </b-row>
      </b-form-group>
      <template v-slot:modal-footer>
        <b-btn variant="primary" class="text-uppercase" v-cs-loading="submiting" @click="handleSubmit">
          {{ isEdit ? 'Save' : 'Add' }}
        </b-btn>
      </template>
    </b-modal>
    <b-modal v-model="mailModal" title="Update and Resend Email" centered footer-class="text-right">
      <b-form-group>
        <template v-slot:label>
          Email
          <span class="text-danger">*</span>
        </template>
        <b-form-input v-model="form.email" :state="validateState($v.form.email)" />
      </b-form-group>
      <template v-slot:modal-footer>
        <b-btn variant="primary" class="text-uppercase" v-cs-loading="submiting" @click="handleResendEmailSubmit">
          {{ 'Update and Send Invite' }}
        </b-btn>
      </template>
    </b-modal>
    <b-modal v-model="confirmationModal" centered title="Attention" title-class="h4" hide-footer>
      <b-card-text>Are you sure you want to <b class="text-danger">delete</b> </b-card-text>
      <div class="d-flex justify-content-end">
        <b-btn variant="primary" class="mr-2" v-cs-loading="submiting" @click="destroyItem">Delete</b-btn>
        <b-btn variant="white" @click="confirmationModal = false">Cancel</b-btn>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { required, numeric, email, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'cooperatives-view-board-members',
  props: ['detailsData'],
  validations: {
    form: {
      id: {},
      first_name: { required },
      last_name: { required },
      email: { required, email },
      phone: { required, minLength: minLength(13), maxLength: maxLength(13) },

      board_president: {},
      board_member_active: {},
    },
  },
  data() {
    return {
      form: {},
      submiting: false,
      loading: false,
      modal: false,
      confirmationModal: false,
      mailModal: false,
      headers: [
        {
          text: 'Name',
          value: 'first_name',
          sortable: true,
        },
        {
          text: 'Type',
          value: 'type',
          align: 'center',
        },
        {
          text: 'Email',
          value: 'attributes.email',
          align: 'center',
        },
        {
          text: 'Phone',
          value: 'attributes.phone',
          align: 'center',
        },
        {
          text: 'Active',
          value: 'status',
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'right',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'boardmembers/items',
      pagination: 'boardmembers/pagination',
    }),
    isEdit() {
      return this.get(this.form, 'id', null);
    },
    cooperativeId() {
      return this.$route.params.cooperativeId;
    },
  },
  async mounted() {
    this.$root.$emit('changeTitle', this.detailsData.company_name);
    await this.handleItems();
  },

  methods: {
    ...mapActions({
      getCooperative: 'cooperatives/show',
      getItems: 'boardmembers/index',
      updateItem: 'boardmembers/update',
      createItem: 'boardmembers/create',
      deleteItem: 'boardmembers/delete',
    }),
    async handelFiltering(filters) {
      const routeQuery = this.$route.query;

      const isSame = this.isSame(routeQuery, filters);

      if (isSame) return;

      await this.$router.replace({ query: filters });

      await this.handleItems();
    },
    handleItems: debounce(async function(e) {
      this.loading = true;

      const query = this.$_.cloneDeep(this.$route.query);
      query.elasticsearch = true;

      if (query.where == undefined) query.where = {};

      if (query.where.cooperative_id != this.cooperativeId) query.where.cooperative_id = this.cooperativeId;

      if (query.status != undefined) query.where.status = query.status;

      if (query.page == undefined) query.page = 1;

      if (query.per == -1) query.per = Math.pow(10, 12);

      await this.$await(this.getItems(query));

      this.loading = false;
    }, 400),

    handleSubmit() {
      if (!this.$v.$invalid) {
        this.submiting = true;
        const action = this.form.id ? this.updateItem : this.createItem;
        action({ admin_user: this.form })
          .then((result) => {
            this.modal = false;
            this.$root.$emit('updateinfoBoardPr', true);
            // this.handleItems();
          })
          .finally(() => {
            this.submiting = false;
          });
      } else {
        this.$v.$touch();
      }
    },

    destroyItem(data = {}) {
      this.submiting = true;
      this.deleteItem(this.form.id)
        .then((result) => {
          this.$root.$emit('updateinfoBoardPr', true);
          this.confirmationModal = false;
        })
        .finally(() => {
          this.submiting = false;
        });
    },

    handleResendEmailSubmit() {
      if (!this.$v.form.email.$invalid) {
        this.submiting = true;
        this.updateItem({ admin_user: this.form, resend_email: true })
          .then((result) => {
            this.mailModal = false;
            this.handleItems();
          })
          .finally(() => {
            this.submiting = false;
          });
      } else {
        this.$v.form.email.$touch();
      }
    },

    updateStatus: debounce(async function(item) {
      this.loading = true;
      const admin_user = { id: item.id, ...item.attributes };
      this.updateItem({ admin_user }).finally((err) => {
        this.loading = false;
      });
    }, 400),

    initForm(data = {}) {
      this.$v.$reset();
      this.form = {
        id: data.id || '',
        first_name: this.get(data, 'attributes.first_name'),
        last_name: this.get(data, 'attributes.last_name'),
        email: this.get(data, 'attributes.email'),
        phone: this.get(data, 'attributes.phone'),
        board_president: Boolean(this.get(data, 'attributes.board_president')),
        board_member_active: this.get(data, 'attributes.board_member_active', null) ?? true,
        cooperative_id: this.cooperativeId,
      };
    },

    openModal(data = {}, type = 'modal') {
      this.initForm(data);
      this[type] = true;
    },
  },
};
</script>
